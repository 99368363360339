<template>
  <div class="col-12 col-lg-8 c g">
    <div class="card">
      <div class="card-header text-center">
        <h4>
          <i class="fa fa-upload"></i>
          استيراد الحجاج من الاكسل
        </h4>
      </div>
      <div class="card-body">
        <div class="form-group col-12 col-lg-4">
          <label for="">الحملة</label>
          <select class="form-control" v-model="hamla">
            <option value="1">البشائر</option>
            <option value="2">الميسر</option>
          </select>
        </div>
        <hr>
        <input type="file" name="" id="ingredient_file" />
        <div id="progress-wrp">
          <div class="progress-bar"></div>
          <div class="status">0%</div>
        </div>
      </div>
      <div class="card-footer" v-if="users.length">
        <div class="col-12 col-lg-6 c g">
            <div class="card card-body">
                <template v-for="status in statuses">
                    <div class="form-check" :key="status">
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" v-model="selected" :value="status">
                        {{ status }}
                      </label>
                    </div>
                </template>
            </div>
        </div>
        <div class="col-12 text-center">
          <button class="btn btn-relief-success" ref="addbtn" @click="add()">
            <i class="fa fa-user-plus"></i>
            اضافة الحجاج للنظام
          </button>
          &nbsp;
          <button class="btn btn-relief-danger" @click="deletet()">
            <i class="fa fa-user-times"></i>
            حذف من تغير حالته الى ملغي
          </button>
        </div>
        <div class="col-12 g table-responsive">
          <table class="table table-hover">
            <thead>
              <th>الإسم</th>
              <th>رقم الهوية</th>
              <th>الحالة</th>
            </thead>
            <tbody>
             <template v-for="user in users">
                <tr v-if="selected.includes(user.status) || selected.length == 0" :key="user[12]">
                    <td>
                    {{ user.name }}
                    </td>
                    <td>
                    {{ user.number }}
                    </td>
                    <td>
                    {{ user.status }}
                    </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      users: [],
      hamla: "1",
      selected: [],
      statuses: [],
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  created() {
    if(!checkPer('users')){
        this.$router.push('/pers')
        return;
    }
    var g = this;
    var Upload = function (file) {
      this.file = file;
    };

    Upload.prototype.getType = function () {
      return this.file.type;
    };
    Upload.prototype.getSize = function () {
      return this.file.size;
    };
    Upload.prototype.getName = function () {
      return this.file.name;
    };
    Upload.prototype.doUpload = function () {
      var that = this;
      var formData = new FormData();

      // add assoc key values, this will be posts values
      formData.append("file", this.file, this.getName());
      formData.append("upload_file", true);

      $.ajax({
        type: "POST",
        url: "https://private-api.brmja.com/excel",
        xhr: function () {
          var myXhr = $.ajaxSettings.xhr();
          if (myXhr.upload) {
            myXhr.upload.addEventListener(
              "progress",
              that.progressHandling,
              false
            );
          }
          return myXhr;
        },
        success: function (data) {
          // your callback here
          g.users = [];
          $("#progress-wrp").fadeOut("slow");
          data[0].forEach(function (student) {
            if (student[12]) {
              student[12] = student[12].toString();
              if (
                parseInt(student[12]) != "NanN" &&
                student[12].trim() != "" &&
                !student[12].includes("ا")
              ) {
                if(!g.statuses.includes(student[0])){
                    g.statuses.push(student[0])
                }
                g.users.push({
                  name: student[10],
                  number: student[12],
                  status: student[0],
                  phone: student[11],
                  order_id: student[13],
                  order_date: student[1],
                  birthdate: student[9],
                  gender: student[8],
                  national: student[7],
                  camp: student[6],
                  package: student[5],
                  slide: student[4],
                  city: student[3],
                  transport: student[2],
                  start_location: student[3],
                  bus_id: "",
                  camp_id: "",
                  notes: ""
                });
              }
            }
          });
        },
        error: function (error) {
          // handle error
          alert("حدث خطأ");
        },
        async: true,
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
        timeout: 60000,
      });
    };

    Upload.prototype.progressHandling = function (event) {
      var percent = 0;
      var position = event.loaded || event.position;
      var total = event.total;
      var progress_bar_id = "#progress-wrp";
      if (event.lengthComputable) {
        percent = Math.ceil((position / total) * 100);
      }
      // update progressbars classes so it fits your code
      $(progress_bar_id + " .progress-bar").css("width", +percent + "%");
      $(progress_bar_id + " .status").text(percent + "%");
    };
    setTimeout(() => {
      $("#ingredient_file").on("change", function (e) {
        $("#progress-wrp").fadeIn("slow");
        var file = $(this)[0].files[0];
        var upload = new Upload(file);

        // maby check size or type here with upload.getSize() and upload.getType()

        // execute upload
        upload.doUpload();
      });
    }, 1000);
  },
  methods: {
    deletet(){
      var g = this;
      if(confirm(`اذا كان هناك حجاج في النظام حالياً وكانت حالتهم في الملف المرفوع الان ملغية فسوف يتم حذفهمن من النظام.`)){
        var arr = [];
        g.users.forEach(function(a){
          if(a?.status?.includes("ملغ")){
            arr.push(a.number)
          }
        })
        $.post(api + '/admin/users/delete-new', {
            jwt: g.user.jwt,
            numbers: JSON.stringify(arr),
            hamla: g.hamla
        }).then(function(r){
            alert(`تم حذف ${r} حاج بنجاح`, 100);
        })
      }
    },
    add() {
      var g = this;
      var arr = [];
      g.users.forEach(function(user){
        if(g.selected.includes(user.status) || g.selected.length == 0){
            user.password = Math.random().toString().split(".")[1].substring(0, 6);
            user.hamla = g.hamla
            arr.push(user);
        }
      })
      g.$refs.addbtn.innerHTML = `<div class="spinner-border text-white" role="status"></div>`;
      $.post(api + "/admin/users/import", {
        jwt: g.user.jwt,
        arr: JSON.stringify(arr)
      })
        .then(function (r) {
          if(r.status == 100){
            alert("تم استيراد الحجاج بنجاح");
          location.reload();
          }else{
            alert(r.response)
          }
        })
        .catch(function (e) {
          console.log(e);
          alert("حدث خطأ..");
        });
    },
  },
};
</script>

<style scoped>
#progress-wrp {
  border: 1px solid #0099cc;
  padding: 1px;
  position: relative;
  height: 30px;
  border-radius: 3px;
  margin: 10px;
  text-align: left;
  background: #fff;
  box-shadow: inset 1px 3px 6px rgba(0, 0, 0, 0.12);
  display: none;
}

#progress-wrp .progress-bar {
  height: 100%;
  border-radius: 3px;
  background-color: greenyellow;
  width: 0;
  box-shadow: inset 1px 1px 10px rgba(0, 0, 0, 0.11);
}

#progress-wrp .status {
  top: 3px;
  left: 50%;
  position: absolute;
  display: inline-block;
  color: #000000;
}
</style>