import { render, staticRenderFns } from "./import.vue?vue&type=template&id=48c7026a&scoped=true&"
import script from "./import.vue?vue&type=script&lang=js&"
export * from "./import.vue?vue&type=script&lang=js&"
import style0 from "./import.vue?vue&type=style&index=0&id=48c7026a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48c7026a",
  null
  
)

export default component.exports